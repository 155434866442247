<template>
  <div
    class="container noColor"
    v-title
    :data-title="$t('i18n.renewManage')"
    id="renewManage"
  >
    <el-card class="box-card">
      <template #header>
        <span>{{ $t("i18n.basicInfo") }}</span>
      </template>
      <div class="text item">
        <jl-form
          :columns="basicColumns"
          @onSubmit="onSubmit"
          @resetForm="goBack"
        ></jl-form>
      </div>
    </el-card>
    <el-card class="box-card m-t">
      <template #header>
        <span>{{ $t("i18n.elevatorList") }}</span>
      </template>
      <div class="text item">
        <jl-table
          @init="getElevatorList"
          :tableData="elevatorData"
          :options="elevatorOptions"
          :columns="elevatorColumns"
          :operates="elevatorOperates"
          :total="elevatorTotal"
          @handleSelectionChange="handleSelectionChange"
          :height="300"
        >
        </jl-table>
      </div>
    </el-card>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import jlForm from "../../components/form";
import jlTable from "../../components/table";
export default {
  name: "RewewUpkeepAdd",
  components: {
    jlForm,
    jlTable,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const state = reactive({
      basicColumns: [
        {
          prop: "number",
          label: "maintenanceContractNo",
          type: "input",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "property",
          label: "contractNature",
          type: "select",
          props: { label: "code", value: "value" },
          data: [],
          uploadUrl: "property",
        },
        {
          prop: "status",
          label: "contractStatus",
          type: "select",
          props: { label: "code", value: "value" },
          data: [],
          uploadUrl: "contractStatus",
        },
        {
          prop: "source",
          label: "contractSource",
          type: "select",
          props: { label: "code", value: "value" },
          data: [],
          uploadUrl: "source",
        },
        {
          prop: "ownerFullname",
          label: "ownerName",
          type: "input",
        },
        {
          prop: "ownerShortname",
          label: "ownerNickname",
          type: "input",
        },
        {
          prop: "firstPartyName",
          label: "partyA",
          type: "input",
        },
        {
          prop: "secondPartyName",
          label: "partyB",
          type: "input",
        },
        {
          prop: "beginDate",
          label: "effectiveDate",
          type: "date",
          change: (val) => {
            setValueByRank(val, "beginDate");
          },
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "endDate",
          label: "expiryDate",
          type: "date",
          change: (val) => {
            setValueByRank(val, "endDate");
          },
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "durationMonth",
          label: "contractDuration",
          type: "input",
          readonly: true,
        },
        {
          prop: "signDate",
          label: "signingDate",
          type: "date",
        },
        {
          prop: "value",
          label: "contractAmount",
          type: "input",
        },
        {
          prop: "remindPhone",
          label: "smsNumber",
          type: "input",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "isSubpackage",
          label: "subcontracting",
          type: "switch",
          text: { aText: "yes", aVal: "1", iText: "no", iVal: "0" },
        },
        {
          prop: "subpackageCompanyName",
          label: "subcontractCompany",
          type: "input",
        },
      ],
      elevatorData: [],
      elevatorTotal: 0,
      elevatorOptions: {
        loading: false, // 是否添加表格loading加载动画
        highlightCurrentRow: true, // 是否支持当前行高亮显示
        multiSelect: route.query.type == 2, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      elevatorColumns: [
        {
          prop: "number",
          label: "elevatorNo",
          align: "center",
          formatter: (row) => {
            return `<a>${row.number}</a>`;
          },
          method: (row) => {
            router.push({
              path: "/elevator/addEditElevator",
              query: { flag: 1, id: row.id },
            });
          },
        },
        {
          prop: "elevatorVarietyStr",
          label: "elevatorType",
          align: "center",
        },
        {
          prop: "manufacturerName",
          label: "manufacturingCompany",
          align: "center",
        },
        {
          prop: "lastCheckDate",
          label: "lastAnnualInspectionDate",
          align: "center",
        },
        {
          prop: "avgValue",
          label: "singleElevatorUpkeepCost",
          align: "center",
        },
      ],
      elevatorOperates: {
        width: 100,
        fixed: "right",
        list: [],
      },
      elevatorRenewStr: "", // 选中的电梯
    });

    const init = () => {
      if (route.query.type == 1) {
        getAllEleIds();
      }
    };

    const getAllEleIds = async () => {
      // 获取合同中所有电梯id
      let { data } = await proxy.$api.maintenance.getAllEleIds(route.query.id);
      state.elevatorRenewStr = data.join(",");
    };

    init();

    const onSubmit = async (params) => {
      if (state.elevatorRenewStr === "") {
        proxy.$defined.tip(t("i18n.selectElevators"));
      } else {
        await proxy.$api.maintenance.isExistContract({
          number: params.number,
        });
        renewContract(params);
      }
    };

    const renewContract = async (params) => {
      let dateArr = ["beginDate", "endDate", "signDate"];
      let data = setData(dateArr, params);
      await proxy.$api.maintenance.setRenew(data);
      let msg = route.query.type == 1 ? "allRenew" : "partialRenew";
      proxy.$defined.tip(t("i18n." + msg) + t("i18n.success"), "success");
      goBack();
    };

    const setData = (arr, data) => {
      let form = JSON.params(JSON.stringify(data));
      arr.map((d) => {
        form[d] = proxy.$defined.format(form[d]);
      });
      form.preId = route.query.id;
      form.elevatorRenewStr = state.elevatorRenewStr.join(",");
      return form;
    };

    const goBack = () => {
      router.go(-1);
    };

    const setValueByRank = (val, key) => {
      // 计算合同时间段
      state[key] = val;
      if (state.beginDate && state.endDate) {
        let sTime = Date.parse(new Date(state.beginDate));
        let eTime = Date.parse(new Date(state.endDate));
        let days = Math.floor((eTime - sTime) / (24 * 3600 * 1000));
        state.basicColumns.map((item) => {
          if (item.prop === "durationMonth") {
            item.value = parseInt(days / 30);
          } else if (item.prop === "duration") {
            item.value = parseInt(days / 365);
          }
        });
      }
    };

    const getElevatorList = async (params) => {
      let id = route.query.id;
      const { data } = await proxy.$api.maintenance.getInAllElevator(
        id,
        params
      );
      state.elevatorData = data.records;
      state.elevatorTotal = data.total;
    };

    const handleSelectionChange = (val) => {
      let elevatorIds = [];
      val.map((ele) => {
        elevatorIds.push(ele.id);
      });
      state.elevatorRenewStr = elevatorIds;
    };

    return {
      ...toRefs(state),
      onSubmit,
      goBack,
      setValueByRank,
      getElevatorList,
      handleSelectionChange,
    };
  },
};
</script>

<style lang="scss">
#renewManage {
  .form-button {
    width: 100%;
    margin: 0;
    text-align: center;
  }
}
</style>
